<template>
  <div>
    <PersonalAccessTokenList />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import PersonalAccessTokenList from '@/components/personalAccessToken/List'

export default {
  name: 'personalAccessToken',

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$ml.get('personalAccessTokens'),
        route: 'personalAccessToken',
      },
    ])
  },
  components: {
    PersonalAccessTokenList,
  },
}
</script>    