<template>
  <Card class="pat-list">
    <div class="row w-100 p-0 m-0">
      <div class="title col-md-6">
        <h3 class="text-dark font-size-secondary m-0">
          {{ $ml.get('personalAccessTokens') }}
        </h3>
      </div>
      <div class="col-md-6 text-right">

        <b-button variant="primary"
                  size="sm"
                  class="rounded-sm"
                  @click="openNewAccessTokenModal()">{{ $ml.get('addNewToken') }}</b-button>
      </div>
    </div>
    <v-data-table :headers="headers"
                  :items="items"
                  :options.sync="options"
                  :items-per-page="16"
                  :server-items-length="totalItems"
                  :loading="loading"
                  class="mt-5"
                  @update:page="changePagination">

      <template v-slot:header.id>
        {{$ml.get('recordNo')}}
      </template>
      <template v-slot:header.created_at>
        {{$ml.get('createdDate')}}
      </template>
      <template v-slot:header.expires_at>
        {{$ml.get('expireDate')}}
      </template>
      <template v-slot:header.actions>
        {{$ml.get('actions')}}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{$customFunctions.setMomentWithFormat(item.created_at,'L LT')}}
      </template>

      <template v-slot:item.expires_at="{ item }">
        {{$customFunctions.setMomentWithFormat(item.expires_at,'L LT')}}
      </template>
      <template v-slot:item.actions="{ item }">
        <ButtonDelete @click.native="deleteToken(item)"></ButtonDelete>
      </template>
    </v-data-table>
    <PersonalAccessTokenModal @addAccessToken="addAccessToken" />
  </Card>
</template>

<script>
import ApiService from '@/core/services/api.service'
import Card from '@/components/Card'
import ButtonDelete from '@/components/buttons/ButtonDelete'
import PersonalAccessTokenModal from '@/components/personalAccessToken/NewTokenModal'

export default {
  name: 'PersonalAccessTokenList',
  data() {
    return {
      loading: false,
      options: {},
      perPage: 0,
      totalItems: 0,
      items: [],
      headers: [
        { text: '', value: 'id', sortable: false },
        { text: '', value: 'created_at', sortable: false },
        { text: '', value: 'expires_at', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
    }
  },
  created() {
    this.getTokens()
  },
  methods: {
    getTokens() {
      this.getTokenList()
        .then((res) => {
          this.setTokenData(res)
        })
        .catch((err) => {
          this.items = []
          console.log(err)
        })
    },
    getTokenList() {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        ApiService.get(
          `${
            process.env.VUE_APP_LOGIN_URL
          }v2/oauth/personal-access-tokens?page=${page ? page : 1}`
        )
          .then(({ data }) => {
            this.loading = false
            resolve(data)
          })
          .catch((err) => {
            this.loading = false
            reject(err)
          })
      })
    },
    setTokenData(res) {
      this.items = res.data
      this.totalItems = res.meta.pagination.total
      this.perPage = res.meta.pagination.count
    },
    changePagination() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      this.getTokens()
    },
    deleteToken(accessToken) {
      this.$bvModal
        .msgBoxConfirm(this.$ml.get('doYouWantToDelete'), {
          title: this.$ml.get('pleaseConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$ml.get('yes'),
          cancelTitle: this.$ml.get('no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true
            ApiService.delete(
              `${process.env.VUE_APP_LOGIN_URL}v2/oauth/personal-access-tokens/${accessToken.id}`
            )
              .then((res) => {
                this.getTokens()
                this.loading = false
                this.generateToast(
                  'success',
                  this.$ml.get('successfullyRemoved')
                )
              })
              .catch((err) => {
                this.loading = false

                if (err == 'Error') {
                  this.generateToast(
                    'danger',
                    this.$ml.get('anErrorHasOccurred')
                  )
                }
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    openNewAccessTokenModal() {
      this.$bvModal.show('modal-addAccessToken')
    },
    addAccessToken() {
      this.getTokens()
    },
  },
  components: {
    Card,
    ButtonDelete,
    PersonalAccessTokenModal,
  },
}
</script>   

<style lang="scss" scoped>
//  .pat-list{
//  }
</style>